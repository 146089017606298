.PanelView {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
}

.wrapper {
  padding: 0 var(--content-container-padding);
  width: 100%;
  max-width: var(--content-max-width);
}
