.Pagination {
  padding: 13px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  background-color: var(--color-5);
  border-bottom: 3px solid var(--color-4);
}

.Pagination p {
  font-size: var(--font-size-md);
}

.select {
  height: 24px;
  display: flex;
  color: var(--font-color-3);
  background-color: var(--color-5);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.select:focus {
  outline: 1px solid red;
}

.select [class*="-control"] {
  box-shadow: none;
  padding: 0 5px;
  height: 24px;
  min-height: auto;
  display: flex;
  align-items: center;
  border-radius: 0;
  background-color: var(--color-12);
  border: none;
}

.select [class$="-ValueContainer"] {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select [class$="-singleValue"] {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--font-color-4);
}

.select [class$="-Input"] {
  position: absolute;
  opacity: 0;
}

.select [class$="-Input"] input {
  position: absolute;
  opacity: 0;
  outline: none;
}

.select [class$="-indicatorContainer"] {
  padding: 0;
  margin: 0;
  width: 12px;
  color: var(--color-5);
  cursor: pointer;
}

.select [class$="-indicatorContainer"]:hover {
  padding: 0;
  margin: 0;
  color: var(--color-8);
  width: 12px;
}

.select [class$="-indicatorSeparator"] {
  display: none;
}

.select [class*="-menu"] {
  right: 0;
  border-radius: 0;
  width: 200%;
  background-color: var(--color-12);
  box-shadow: none;
}

.select [class*="-MenuList"] {
  border-radius: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.select [class$="-option"] {
  height: 24px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-2);
  background-color: var(--color-12);
  cursor: pointer;
  transition: all var(--transition-speed-fast) ease;
}
.select [class$="-option"]:hover {
  background-color: var(--color-11);
}

.select [class$="-option"]:focus {
  background-color: var(--color-15);
}

.site {
  min-width: 90px;
  text-align: center;
}
