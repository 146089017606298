.Login {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.logo {
  max-width: 124px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.buttons {
  align-self: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 27px;
}

.error {
  width: 100%;
  height: var(--font-size-lg);
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-medium);
  color: var(--color-15);
}

.link {
  font-size: var(--font-size-nm);
  color: var(--color-12);
  border-bottom: 1px solid transparent;
  transition: all var(--transition-speed-fast) ease;
}

.link:hover {
  border-bottom: 1px solid var(--color-12);
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
}
