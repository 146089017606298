.FormGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.FormGroup h3 {
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-medium);
}

.items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.items > p {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.displayColumn {
  flex-direction: column;
  align-items: flex-start;
}
