*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* global fonts variables */
  --primary-font: "Catamaran", sans-serif;

  --font-size-sm: 1.2rem; /* 12px */
  --font-size-nm: 1.4rem; /* 14px */
  --font-size-md: 1.6rem; /* 15px, 16px */
  --font-size-lg: 1.8rem; /* 18px */
  --font-size-xl: 2rem; /* 20px */
  --font-size-xxl: 2.2rem; /* 22px */
  --font-size-xxxl: 2.4rem; /* 24px */

  --font-weight-regular: 300;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  /* colors dark mode */
  --color-dark-mode-1: #0a0a0a;
  --color-dark-mode-2: #1e1e1f;
  --color-dark-mode-2-hover: hsl(240, 2%, 14%);
  --color-dark-mode-3: #202122;
  --color-dark-mode-4: #222224;
  --color-dark-mode-5: #292a2b;
  --color-dark-mode-5-hover: hsl(228, 4%, 21%);
  --color-dark-mode-6: #333333;
  --color-dark-mode-7: #4d4d4d;
  --color-dark-mode-7-hover: hsl(0, 0%, 40%);
  --color-dark-mode-8: #666666;
  --color-dark-mode-9: #7e7e7e;
  --color-dark-mode-10: #9e9e9e;
  --color-dark-mode-11: #cfcfcf;
  --color-dark-mode-12: #f7f7f7;
  --color-dark-mode-13: #0b8bd4;
  --color-dark-mode-13-hover: hsl(202, 90%, 54%);
  --color-dark-mode-14: #172a35;
  --color-dark-mode-14-hover: hsl(202, 39%, 20%);
  --color-dark-mode-15: #e02735;
  --color-dark-mode-15-hover: hsl(
    355,
    75%,
    62%
  ); /* example hover state variable */
  --color-dark-mode-16: #2be43a;
  --font-color-dark-mode-2: #f7f7f7;
  --font-color-dark-mode-3: #f7f7f7;
  --font-color-dark-mode-4: #0a0a0a;

  /* colors light mode */
  --color-light-mode-1: #f7f7f7;
  --color-light-mode-2: #cfcfcf;
  --color-light-mode-2-hover: hsl(0, 0%, 72%);
  --color-light-mode-3: #b9b9b9;
  --color-light-mode-4: #f0f0f0;
  --color-light-mode-5: #cccaca;
  --color-light-mode-5: #dddddd;
  --color-light-mode-5-hover: hsl(0, 0%, 82%);
  --color-light-mode-6: #4d4d4d;
  --color-light-mode-7: #afafaf;
  --color-light-mode-7-hover: hsl(0, 0%, 40%);
  --color-light-mode-8: #292a2b;
  --color-light-mode-9: #222224;
  --color-light-mode-10: #202122;
  --color-light-mode-11: #1e1e1f;
  --color-light-mode-12: #0a0a0a;
  --color-light-mode-13: #0b8bd4;
  --color-light-mode-13-hover: hsl(202, 90%, 54%);
  --color-light-mode-14: #172a35;
  --color-light-mode-14-hover: hsl(202, 39%, 20%);
  --color-light-mode-15: #e02735;
  --color-light-mode-15-hover: hsl(
    355,
    75%,
    62%
  ); /* example hover state variable */
  --color-light-mode-16: #2be43a;
  --font-color-light-mode-2: #f7f7f7;
  --font-color-light-mode-3: #0a0a0a;
  --font-color-light-mode-4: #f7f7f7;

  /* colors (use this colors only in components!!!!) */
  --color-1: var(--color-dark-mode-1);
  --color-2: var(--color-dark-mode-2);
  --color-2-hover: var(--color-dark-mode-2-hover);
  --color-3: var(--color-dark-mode-3);
  --color-4: var(--color-dark-mode-4);
  --color-5: var(--color-dark-mode-5);
  --color-5-hover: var(--color-dark-mode-5-hover);
  --color-6: var(--color-dark-mode-6);
  --color-7: var(--color-dark-mode-7);
  --color-7-hover: var(--color-dark-mode-7-hover);
  --color-8: var(--color-dark-mode-8);
  --color-9: var(--color-dark-mode-9);
  --color-10: var(--color-dark-mode-10);
  --color-11: var(--color-dark-mode-11);
  --color-12: var(--color-dark-mode-12);
  --color-13: var(--color-dark-mode-13);
  --color-13-hover: var(--color-dark-mode-13-hover);
  --color-14: var(--color-dark-mode-14);
  --color-14-hover: var(--color-dark-mode-14-hover);
  --color-15: var(--color-dark-mode-15);
  --color-15-hover: var(--color-dark-mode-15-hover);
  --color-16: var(--color-dark-mode-16);
  --overlay-color: #0a0a0a80;
  --font-color-2: var(--font-color-dark-mode-2);
  --font-color-3: var(--font-color-dark-mode-3);

  /* --color-1: var(--color-light-mode-1);
  --color-2: var(--color-light-mode-2);
  --color-2-hover: var(--color-light-mode-2-hover);
  --color-3: var(--color-light-mode-3);
  --color-4: var(--color-light-mode-4);
  --color-5: var(--color-light-mode-5);
  --color-5-hover: var(--color-light-mode-5-hover);
  --color-6: var(--color-light-mode-6);
  --color-7: var(--color-light-mode-7);
  --color-7-hover: var(--color-light-mode-7-hover);
  --color-8: var(--color-light-mode-8);
  --color-9: var(--color-light-mode-9);
  --color-10: var(--color-light-mode-10);
  --color-11: var(--color-light-mode-11);
  --color-12: var(--color-light-mode-12);
  --color-13: var(--color-light-mode-13);
  --color-13-hover: var(--color-light-mode-13-hover);
  --color-14: var(--color-light-mode-14);
  --color-14-hover: var(--color-light-mode-14-hover);
  --color-15: var(--color-light-mode-15);
  --color-15-hover: var(--color-light-mode-15-hover);
  --color-16: var(--color-light-mode-16); */

  /* margins */
  --margin-sm: 8px;
  --margin-nm: 10px;
  --margin-md: 15px;
  --margin-lg: 20px;
  --margin-xl: 30px;

  /* paddings */
  --padding-sm: 8px;
  --padding-nm: 10px;
  --padding-md: 15px;
  --padding-lg: 20px;
  --padding-xl: 30px;

  /* transitions */
  --transition-speed-fast: 0.2s;
  --transition-speed-normal: 0.3s;
  --transition-speed-slow: 0.5s;

  /* sizes */
  --content-container-padding: 14px;

  --content-max-width: calc(
    1430px - calc(var(--content-container-padding) * 2)
  );

  --header-height: 80px;

  --avatar-size-nm: 45px;
  --avatar-size-lg: 150px;
}

html {
  font-size: 10px; /* 1rem = 10px */
}

body {
  margin: 0;
  font-family: var(--primary-font);
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-regular);
  letter-spacing: 1px;
  color: var(--color-12);
  background-color: var(--color-4);
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: var(--primary-font);
}

a {
  text-decoration: none;
}

button,
[type="submit"] {
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-family: var(--primary-font);
}

input {
  font-family: var(--primary-font);
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-regular);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactModalPortal {
  z-index: 99 !important;
  position: relative;
}
