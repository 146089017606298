.SearchPanel {
  padding: 13px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-5);
  border-top: 3px solid var(--color-4);
  border-bottom: 3px solid var(--color-4);
}

.actions {
  display: flex;
  gap: 10px;
}
