.Avatar {
  display: block;
  border-radius: 50%;
  background-color: var(--color-10);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.normal {
  width: var(--avatar-size-nm);
  height: var(--avatar-size-nm);
}

.large {
  width: var(--avatar-size-lg);
  height: var(--avatar-size-lg);
}
