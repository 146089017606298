.Back {
  padding: 0 14px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: var(--color-5);
}

.button {
  display: flex;
  align-items: center;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  color: var(--color-12);
  cursor: pointer;
}

.button i {
  font-size: var(--font-size-xxxl);
  color: var(--color-8);
  transition: all var(--transition-speed-fast) ease;
}

.button:hover i {
  font-size: var(--font-size-xxxl);
  color: var(--color-10);
}
