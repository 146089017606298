.ChangePasswordForm {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.buttons {
  align-self: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 27px;
}

.info {
  width: 100%;
  height: var(--font-size-lg);
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-medium);
  color: var(--color-15);
}

.info.success {
  color: var(--color-16);
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
}
