.TechInfo {
}

.TechInfo .center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.TechInfo h2 {
  padding: 0 26px;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  line-height: 60px;
  background-color: var(--color-5);
}

.TechInfo > p {
  padding: 17px 26px 30px 26px;
  font-size: var(--font-size-md);
  line-height: 150%;
}

.TechInfo > div {
  padding: 17px 26px 30px 26px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.TechInfo.cards > div {
  padding: 0;
  flex-direction: row;
  gap: 2px;
  background-color: var(--color-5);
}
