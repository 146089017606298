.TabsNavigation {
  position: relative;
  background-color: var(--color-5);
}

.wrapper {
  display: flex;
  list-style: none;
}

.link {
  padding: 0 34px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-lg);
  color: var(--color-11);
  cursor: pointer;
  transition: all var(--transition-speed-fast) ease;
}

.active {
  color: var(--color-12);
}

.bar {
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: var(--color-15);
  transition: all var(--transition-speed-fast) ease;
}
