.PersonalDetails {
  padding: 30px 22px;
  width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: start;
  gap: 12px;
  background-color: var(--color-5);
  overflow: hidden;
}

.PersonalDetails h1 {
  margin-top: 12px;
  font-size: var(--font-size-xxxl);
  font-weight: var(--font-weight-medium);
  line-height: 100%;
}

.contact {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: start;
}

.contact p {
  display: flex;
  align-items: center;
  gap: 14px;
  font-size: var(--font-size-md);
  line-height: 150%;
  word-break: break-all;
}

.contact i {
  font-size: calc(var(--font-size-md) * 1.4);
  color: var(--color-7);
}

.about h2 {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-7);
}

.about p {
  font-size: var(--font-size-md);
  line-height: 150%;
}
