.PrimaryButton {
  padding: 5px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  transition: all var(--transition-speed-fast) ease;
  line-height: 100%;
  user-select: none;
  cursor: pointer;
}
.PrimaryButton:disabled {
  cursor: not-allowed;
}

.reversed {
  flex-direction: row-reverse;
  gap: 2px;
}

.PrimaryButton i {
  font-size: var(--font-size-md);
  transition: all var(--transition-speed-fast) ease;
}

.PrimaryButton:hover i {
  color: var(--color-7-hover);
}

.small {
  min-height: 27px;
}

.normal {
  min-height: 30px;
}

.large {
  min-height: 40px;
}

.form {
  padding: 5px 9px;
  min-height: 27px;
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-medium);
}

.form i {
  font-size: var(--font-size-nm);
}

.fullWidth {
  width: 100%;
}

.primaryFont {
  color: var(--font-color-3);
}

.secondaryFont {
  color: var(--font-color-2);
}

.primary {
  background-color: var(--color-15);
}

.primary:hover {
  background-color: var(--color-15-hover);
}

.secondary {
  background-color: var(--color-5);
}

.secondary:hover {
  background-color: var(--color-5-hover);
}

.tertiary {
  background-color: var(--color-2);
}

.tertiary:hover {
  background-color: var(--color-2-hover);
}

.quaternary {
  background-color: var(--color-14);
}

.quaternary:hover {
  background-color: var(--color-14-hover);
}

.primaryIcon {
  color: var(--color-7);
}

.PrimaryButton:hover .primaryIcon {
  color: var(--color-7-hover);
}

.secondaryIcon {
  color: var(--font-color-2);
}

.PrimaryButton:hover .secondaryIcon {
  color: var(--font-color-2);
}

.tertiaryIcon {
  color: var(--color-15);
}

.PrimaryButton:hover .tertiaryIcon {
  color: var(--color-15-hover);
}
