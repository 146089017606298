.Input {
  position: relative;
}

.InputInput {
  color: var(--color-12);
  border: none;
  outline: 1px solid transparent;
  transition: all var(--transition-speed-fast) ease;
}

.InputInput::placeholder {
  color: var(--color-9);
  transition: all var(--transition-speed-fast) ease;
}

.InputInput:hover::placeholder {
  color: var(--color-11);
}

.InputInput:focus {
  outline: 1px solid var(--color-15);
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto !important;
  display: flex;
  align-items: center;
  color: var(--color-9);
}

.icon.normal {
  padding: 0 7px 0 9px;
  font-size: calc(var(--font-size-sm) * 1.2);
}

.icon.large {
  padding: 0 10px 0 12px;
  font-size: calc(var(--font-size-md) * 1.2);
}

.normal.extendedPadding {
  padding: 0 9px 0 calc(16px + var(--font-size-sm) * 1.2);
}

.large.extendedPadding {
  padding: 0 12px 0 calc(22px + var(--font-size-md) * 1.2);
}

.normal {
  padding: 0 9px;
  width: 95px;
  height: 27px;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.medium {
  padding: 0 12px;
  width: 270px;
  height: 34px;
  font-size: var(--font-size-md);
}

.large {
  padding: 0 12px;
  width: 350px;
  height: 40px;
  font-size: var(--font-size-md);
}

.fullWidth {
  width: 100%;
}

.primary {
  background-color: var(--color-5);
}

.primary:hover {
  background-color: var(--color-5-hover);
}

.secondary {
  background-color: var(--color-2);
}

.secondary:hover {
  background-color: var(--color-2-hover);
}
