.Button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-xxl);
  color: var(--color-5);
  background-color: var(--color-11);
  transition: all var(--transition-speed-fast);
}

.Button:hover {
  background-color: var(--color-12);
}

.Button:disabled {
  background-color: var(--color-8);
}

.Button:hover:disabled {
  cursor: not-allowed;
}
