.NoMatch {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.NoMatch h1 {
  font-size: var(--font-size-xxxl);
}
