.EditHrForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.EditHrForm h2 {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-bold);
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.bottomButtons {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.info {
  width: 100%;
  height: var(--font-size-lg);
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-medium);
  color: var(--color-15);
}

.info.success {
  color: var(--color-16);
}
