.ConfirmationPrompt {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--color-1);
}

.row {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.question {
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-medium);
}
