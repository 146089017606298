.Header {
  background-color: var(--color-2);
  width: 100%;
  height: var(--header-height);
  display: flex;
  justify-content: center;
  z-index: 1;
}

.wrapper {
  padding: 0 var(--content-container-padding);
  width: 100%;
  max-width: var(--content-max-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 80%;
}
