.UsersListView {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper {
  z-index: 0;
  overflow: hidden;
}

.noResults {
  text-align: center;
  line-height: 500%;
}
