.InfoPrompt {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--color-1);
}

.row {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.infoText {
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-medium);
}

.info {
  width: 100%;
  height: var(--font-size-lg);
  font-size: var(--font-size-nm);
  font-weight: var(--font-weight-medium);
  color: var(--color-15);
}

.info.success {
  color: var(--color-16);
}
